require("flatpickr/dist/flatpickr.css")
import flatpickr from "flatpickr/dist/flatpickr"
import { Japanese } from "flatpickr/dist/l10n/ja.js"

flatpickr.localize(Japanese)

document.addEventListener('turbo:before-cache', () => {
  flatpickr("[data-behavior='flatpickr-interview-start-at']")
  flatpickr("[data-behavior='flatpickr-interview-started-at']")
})

document.addEventListener("turbo:load", () => {
  const config = {
    altInput: true,
    altFormat: "Y年m月d日 H:i",
    enableTime: true,
    inline: true,
    maxTime: "19:00",
    minTime: "09:00",
    minuteIncrement: 15,
    time_24hr: true,
  }

  flatpickr("[data-behavior='flatpickr-interview-start-at']", config)
  const startedAtFlatpickr = flatpickr("[data-behavior='flatpickr-interview-started-at']", config)
  clickToSetStartedAt(startedAtFlatpickr)
})

function clickToSetStartedAt(startedAtFlatpickr) {
  const copyButton = document.getElementById('copy-start-at-button')
  if(copyButton) {
    copyButton.addEventListener('click', () => {
      const startedAt = document.querySelector("[data-behavior='flatpickr-interview-start-at']").value
      startedAtFlatpickr.setDate(startedAt, true)
    })
  }
}
